import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Section, MainHeading } from '../globalStyles'
import { HeroText } from '../components/Hero/HeroStyles'
import './MultiplicacionSemillas.css'
import cosecha from '../assets/cosechaRadix.jpg'
import {
	ImgWrapper,
	Img,
} from '../components/Content/ContentStyle.js';



export default function MultiplicacionSemillas() {
  const { t } = useTranslation()
  return (
        <Section>
          <Container>
            <MainHeading className='title' style={{fontSize : "3rem"}}>{t("aboutusTitle")}</MainHeading>
            <br/>
            <HeroText style={{textAlign : "justify", textJustify : "inter-word"}}>
            {t("aboutText")}
            <br/>
            <br/>
            <br/>
            <br/>

            <ImgWrapper>
				<Img
					src={cosecha}
					alt={"Fockink"}
					// whileHover={{ rotate: 2, scale: 1.02 }}
					// transition={{ duration: 0.5 }}
                // width={600}
				/>
			</ImgWrapper>

            <br/>
            <br/>
            {t("aboutText1")}

            </HeroText>
            <br/>
            <br/>
            <MainHeading className='title' style={{fontSize : "3rem"}}>{t("aboutText2")}</MainHeading>
            <br/>
            <HeroText style={{textAlign : "justify", textJustify : "inter-word"}}>
            {t("aboutText3")}
            <br/>
            <br/>
            {t("aboutText4")}
            <br/>
            <br/>
            {t("aboutText5")}
            <br/>
            <br/>
            {t("aboutText6")}
            <br/>
            {t("aboutText7")}
            <br/>
            {t("aboutText8")}
            <br/>
            <br/>
            {t("aboutText9")}

            </HeroText>
          </Container>
        </Section>
  )
}
