import React from 'react';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { Link } from 'react-router-dom';
import { Button, Container, MainHeading } from '../../globalStyles';
import { HeroVideo, HeroSection, HeroText, ButtonWrapper, HeroButton } from './HeroStyles';
import Clientes from '../Carousel/Clientes';

const languages = [
	{
	  code: 'es',
	  name: 'Español',
	  country_code: 'es',
	},
	{
	  code: 'en',
	  name: 'English',
	  country_code: 'gb',
	},
	{
	  code: 'chn',
	  name: '中國人',
	  country_code: 'chn',
	},
  ]

export default function Hero() {
	const { t } = useTranslation()
  return (
    <HeroSection>
			<HeroVideo className='image'/>
			<Container>
				<MainHeading>{t("page_title")}</MainHeading>
				<HeroText>
                {t("radix_service")}
				</HeroText>
				<ButtonWrapper>
					<Link to="signup">
						<Button>{t("btn_contacto")}</Button>
					</Link >
					<Link to="productos">
					<HeroButton>{t("btn_productos")}</HeroButton>
					</Link >
				</ButtonWrapper>
			</Container>
			<Clientes/>
		</HeroSection>
  )
}
