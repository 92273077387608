import React from 'react'
import Hero from '../components/Hero/Hero'
import Features from '../components/Features/Features';
import { Content } from '../components/Content/Content';
import { heroOne, heroTwo, heroThree } from '../data/HeroData';
import WhatsappButton from '../components/Whatsapp/WhatsappButton';
import { Content1 } from '../components/Content/Content1';
import { Content2 } from '../components/Content/Content2';


export default function Home() {
  return (
    <>
    <Hero/>
    <Features />
    <Content {...heroOne} />
    <Content1 {...heroTwo} />
		<Content2 {...heroThree} />
    </>
  )
}
