import React from 'react'
import './FormStyles.css'
import { useTranslation } from 'react-i18next'
import { Container, Section } from '../../globalStyles';
import { ContentButton } from '../Content/ContentStyle';
import { ButtonWrapper, HeroButton } from '../Hero/HeroStyles';
import {MdOutlineMail} from 'react-icons/md'
import {BsWhatsapp} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { send } from 'emailjs-com'

export default function Form() {
  const { t } = useTranslation()
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ut6uoa9', 'template_8ux6nxa', form.current, 'SybZOjLNDyw1TH4SK')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    e.target.reset()
  };
  return (
    <Section>
		<Container>
      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <MdOutlineMail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>comercial1@radixagro.com</h5>
            <a href="mailto:comercial1@radixagro.com" target='_blank' rel="noreferrer" style={{textDecoration: "none", color: "white"}}>Send a message</a>
          </article>
          <article className='contact__option'>
            <BsInstagram className='contact__option-icon'/>
            <h4>Instagram</h4>
            <h5>Radixagro</h5>
            <a href="https://www.instagram.com/radixagro/" target='_blank' rel="noreferrer" style={{textDecoration: "none", color: "white"}}>radixagro</a>
          </article>
          {/* <article className='contact__option'>
            <BsWhatsapp className='contact__option-icon'/>
            <h4>Whatsapp</h4>
            <h5>+5411657352349</h5>
            <a href="https://wa.me/+541155252721" target='_blank' rel="noreferrer">Send a message</a>
          </article>       */}
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder={t("placeHolder1")} required/>
          <input type="email" name='email' placeholder={t("placeHolder2")} required/>
          <textarea name="message" placeholder={t("placeHolder3")} rows="7" required></textarea>
		  <ButtonWrapper>
          	<HeroButton type='submit'>Send Message</HeroButton>
		  </ButtonWrapper>
        </form>
      </div>

		</Container>
    </Section>
  )
}