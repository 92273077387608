import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'
import {BsGlobe} from 'react-icons/bs'



const languages = [
    {
      code: 'es',
      name: 'Español',
      country_code: 'es',
    },
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
    },
    {
      code: 'chn',
      name: '中國人',
       dir: 'rtl',
      country_code: 'chn',
    },
  ]



export default function LanguageSelect({closeMobileMenu}) {
    const currentLanguageCode = cookies.get('i18next') || 'es'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation();
  return (
    
     <div className="language-select">
         <div className="d-flex justify-content-end align-items-center language-select-root">
           <div className="dropdown">
             <button
               className="btn btn-link dropdown-toggle"
               type="button"
               id="dropdownMenuButton1"
               data-bs-toggle="dropdown"
               aria-expanded="false"
             >
               <BsGlobe />
             </button>
             <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
               <li>
                 <span className="dropdown-item-text">{t('language')}</span>
               </li>
               {languages.map(({ code, name, country_code }) => (
                 <li key={country_code}>
                   <a
                     href="#"
                     className={classNames('dropdown-item', {
                      //  disabled: currentLanguageCode === code,
                     })}
                     onClick={() => {
                       i18next.changeLanguage(code);
                       closeMobileMenu()
                     }}
                   >
                     <span
                       className={`flag-icon flag-icon-${country_code} mx-2`}
                       style={{
                         opacity: currentLanguageCode === code ? 0.5 : 1,
                       }}
                     ></span>
                     {name}
                   </a>
                 </li>
               ))}
             </ul>
           </div>
         </div>
       </div>
  )
}
