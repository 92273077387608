import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Section } from '../../globalStyles';
import { Link } from 'react-router-dom';
import { MdReadMore } from 'react-icons/md';
import {
	FeatureText,
	FeatureTitle,
	FeatureWrapper,
	FeatureColumn,
	FeatureImageWrapper,
	FeatureName,
	FeatureTextWrapper,
} from './FeaturesStyles';
import { featuresData } from '../../data/FeaturesData';
import background from '../../assets/background1.jpeg'

const Features = () => {
	const { t } = useTranslation();
	const initial = {
		y: 40,
		opacity: 0,
	};
	const animate = {
		y: 0,
		opacity: 1,
	};

	return (
		<Section smPadding="50px 10px" position="relative" inverse id="about">
			<Container>
				<FeatureTextWrapper>
					<FeatureTitle>{t("features_title")}</FeatureTitle>
				</FeatureTextWrapper>
				<FeatureWrapper>
					{featuresData.map((el, index) => (
						<Link style={{
								textDecoration : "none",
								color : "black"}}
								to={el.link}
								onClick={() => {
									window.scrollTo({
									  top: 0,
									  behavior: "smooth"
									});
								  }}>
							<FeatureColumn
								initial={initial}
								animate={animate}
								transition={{ duration: 0.5 + index * 0.1 }}
								key={index}
							>
								<FeatureImageWrapper className={el.imgClass}>
								<div></div>{el.icon}
								</FeatureImageWrapper>
								<FeatureName>{t(`features_data.${index}.name`)}</FeatureName>
								
								{el.description}
							</FeatureColumn>
								</Link>
						
					))}
				</FeatureWrapper>
			</Container>
		</Section>
	);
};

export default Features;