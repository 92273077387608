import React from 'react'
import { useTranslation } from 'react-i18next'
import './Carousel.css'
import { Link } from 'react-router-dom'
import DonMario from '../../assets/DonMario.svg'
import Corteva from '../../assets/Corteva.png'
import Bayer from '../../assets/Bayer.png'
import Pigasol from '../../assets/Pigasol.png'
import Stine from '../../assets/Stine.png'
import {FeatureTitle} from '../Features/FeaturesStyles'

export default function Clientes() {
    const { t } = useTranslation()
  return (
    <div class='slider-container'>   
    <FeatureTitle style={{color : 'white', fontSize: "2rem"}}>{t("client_title")}</FeatureTitle>
    <div class="slider">
        <div class="slide-track">
            <div class="slide">
                <Link to={'https://www.donmario.com/'}>
                 <img src={DonMario} alt=""/>
                </Link>
               
            </div>
            <div class="slide">
                <Link to={'https://www.corteva.com.ar/'}>
                <img src={Corteva} alt=""/>
                </Link>
                
            </div>
            <div class="slide">
                <Link to={'https://www.conosur.bayer.com/es/'}>
                <img src={Bayer} alt=""/>
                </Link>
                
            </div>
            <div class="slide">
                <Link to={'https://www.pigasol.com/index'}>
                <img src={Pigasol} alt=""/>
                </Link>
                
            </div>
            <div class="slide">
                <Link to={'https://stinesemillas.com.ar/'}>
                <img src={Stine} alt=""/>
                </Link>
                
            </div>
            <div class="slide">
                <Link to={'https://www.donmario.com/'}>
                 <img src={DonMario} alt=""/>
                </Link>
               
            </div>
            <div class="slide">
                <Link to={'https://www.corteva.com.ar/'}>
                <img src={Corteva} alt=""/>
                </Link>
                
            </div>
            <div class="slide">
                <Link to={'https://www.conosur.bayer.com/es/'}>
                <img src={Bayer} alt=""/>
                </Link>
                
            </div>
            <div class="slide">
                <Link to={'https://www.pigasol.com/index'}>
                <img src={Pigasol} alt=""/>
                </Link>
                
            </div>
            <div class="slide">
                <Link to={'https://stinesemillas.com.ar/'}>
                <img src={Stine} alt=""/>
                </Link>
                
            </div>

        </div>
    </div>
    </div>
  )
}