import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Heading, TextWrapper } from '../../globalStyles';
import { IconContext } from 'react-icons/lib';
import {
	PricingSection,
	PricingWrapper,
	PricingContainer,
	PricingCardInfo,
	PricingCardPlan,
	PricingCardCost,
	PricingCardFeatures,
	PricingCardText,
	PricingCardFeature,
	PricingCard,
} from './ProductStyles';
import { productData } from '../../data/ProductData';
import festucaFlexible from '../../assets/festucaFlexible.jpg'
import festucaRizomat from '../../assets/festucaRizomat.jpg'
import RyegrassTurftypeDonLuis from '../../assets/RyegrassTurftypeDonLuis.jpg'
import SorgoForrajeroColiqueo from '../../assets/SorgoForrajeroColiqueo.jpg'
import SorgoGranifero from '../../assets/SorgoGranifero.jpg'
import TenuisyCorniculatus from '../../assets/TenuisyCorniculatus.jpg'
import mezclaCordillerana from '../../assets/mezclaCordillerana.webp'
import Estanzuela284 from '../../assets/Estanzuela284.webp'

const images = [
	festucaFlexible,
	festucaRizomat,
	TenuisyCorniculatus,
	SorgoForrajeroColiqueo,
	SorgoGranifero,
	Estanzuela284,
	RyegrassTurftypeDonLuis,
	mezclaCordillerana

]


function Pricing() {
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState(productData.map(() => false));
	return (
		<IconContext.Provider value={{ color: '#a9b3c1', size: '1rem' }}>
			<PricingSection id="pricing" style={{backgroundColor : "rgba(213, 228, 200, 0.8)"}}>
				<PricingWrapper>
					<Heading style={{marginBottom : '2remi', color: "black"}}>{t("product_title")}</Heading>

					<TextWrapper
						mb="1.4rem"
						weight="600"
						size="1.1rem"
						color="white"
						align="center"
					>
					
					</TextWrapper>
					<PricingContainer>
						{productData.map((card, index1) => (
							<PricingCard key={index1}>
								<PricingCardInfo>
									<PricingCardPlan>{t(`product_data.${index1}.title`)}</PricingCardPlan>
									<PricingCardCost>{t(`product_data.${index1}.type`)}</PricingCardCost>
									{/* <PricingCardCost>{t(`product_data.${index1}.price`)}</PricingCardCost> */}
									<PricingCardText>{card.description}</PricingCardText>
									{expanded[index1] ? (
										<PricingCardFeatures>
											{card.features.map((feature, index) => (
												<PricingCardFeature key={index}>
													{t(`product_data.${index1}.features.${index}`)}
												</PricingCardFeature>
											))}
										</PricingCardFeatures>
									) : null}
									<img style={{borderRadius : "1rem", marginBottom: "1rem"}} src={images[index1]} alt={t(`product_data.${index1}.title`)}/>
									<Button  onClick={() => setExpanded(expanded.map((value, i) => i === index1 ? !value : value))}>
										{expanded[index1] ? t('showLess') : t('showMore')}
									</Button>
								</PricingCardInfo>
							</PricingCard>
						))}
					</PricingContainer>
				</PricingWrapper>
			</PricingSection>
		</IconContext.Provider>
	);
}
export default Pricing;