import React from 'react'
import { WhatsappLink, WhatsappIcon } from './WhatsappButtonStyles'

export default function WhatsappButton() {
  return (
    <WhatsappLink href="https://wa.me/+541164752349" target='_blank' rel="noreferrer">
        <WhatsappIcon className="fa fa-whatsapp my-float"></WhatsappIcon>
    </WhatsappLink>
  )
}
