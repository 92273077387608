import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Section, MainHeading } from '../globalStyles'
import { HeroText } from '../components/Hero/HeroStyles'
import fockink from '../assets/fockink.webp'
import valley from '../assets/valley.webp'
import TL from '../assets/TL.webp'
import {
	ImgWrapper,
	Img,
} from '../components/Content/ContentStyle.js';


export default function EquipoRiego() {
  const { t } = useTranslation()
  return (
    <Section>
          <Container>
          <MainHeading style={{fontSize : "3rem"}}>{t("riegoTitle")}</MainHeading>
          <br/>
          <br/>
          <br/>
          <HeroText style={{textAlign : "justify", textJustify : "inter-word"}}>
          <h3>{t("riegoFockink")}</h3>

          <br/>
          <br/>
          
          {t("riegoText1")}

          <br/>
          <br/>
          <br/>

          <ImgWrapper>
							<Img
								src={fockink}
								alt={"Fockink"}
								// whileHover={{ rotate: 2, scale: 1.02 }}
								// transition={{ duration: 0.5 }}
                // width={600}
							/>
						</ImgWrapper>

          <br/>
          <br/>
          <br/>

          <h3>{t("riegoValley")}</h3>
          
          <br/>
          <br/>

          {t("riegoText2")}

          <br/>
          <br/>
          <br/>

          <ImgWrapper>
							<Img
								src={valley}
								alt={"Valley"}
								// whileHover={{ rotate: 2, scale: 1.02 }}
								// transition={{ duration: 0.5 }}
                // width={600}
							/>
						</ImgWrapper>

          <br/>
          <br/>
          <br/>

          <h3>{t("riegoTL")}</h3>
          
          <br/>
          <br/>
          
          {t("riegoText3")}

          <br/>
          <br/>
          <br/>

          <ImgWrapper>
							<Img
								src={TL}
								alt={"TL"}
								// whileHover={{ rotate: 2, scale: 1.02 }}
								// transition={{ duration: 0.5 }}
                // width={600}
							/>
						</ImgWrapper>

          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>

          {t("equipos")}

				</HeroText>
          </Container>
        </Section>
  )
}
