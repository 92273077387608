import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { FaRProject, FaTimes } from 'react-icons/fa';
import { CgMenuRight } from 'react-icons/cg';
import { IconContext } from 'react-icons';
import logo from '../../assets/logo2.0.png';
import LanguageSelect from '../Languages/LanguageSelect';
import {
	Nav,
	NavbarContainer,
	NavLogo,
	NavIcon,
	MobileIcon,
	NavMenu,
	NavLinks,
	NavItem,
	RadixagroTitle,
} from './NavbarStyles.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { data } from '../../data/NavbarData';

const Navbar = ({color}) => {
	const { t } = useTranslation();
	const [show, setShow] = useState(false);

	const colortitle = show ? 'white' : 'black';

	let navigate = useNavigate();
	let location = useLocation();

	const handleClick = () => {
		setShow(!show);
	};

	const scrollTo = (id) => {
		const element = document.getElementById(id);

		element.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const closeMobileMenu = (to, id) => {
		if (id && location.pathname === '/') {
			scrollTo(id)
			  
		}

		navigate(to);
		setShow(false);
	};
	const [scrollNav, setScrollNav] = useState(false);

	const changeNav = () => {
	  if (window.scrollY >= 80) {
		setScrollNav(true);
	  } else {
		setScrollNav(false);
	  }
	};
  
	useEffect(() => {
	  window.addEventListener('scroll', changeNav);
	}, []);

	return (
		<IconContext.Provider value={{ color: 'white'}}>
			<Nav scrollNav={scrollNav}>
				<NavbarContainer >
					<NavLogo to="/">
						<img src={logo} alt="logo" />
						<RadixagroTitle color={color} style={{color : `${colortitle}}`}} scrollNav={scrollNav}>Radixagro</RadixagroTitle>
					</NavLogo>
					<MobileIcon onClick={handleClick}>
						{show ? <FaTimes /> : <CgMenuRight style={{fill : scrollNav ? 'black' : 'white'}} />}
					</MobileIcon>
					<NavMenu show={show} >
						{data.map((el, index) => (
							<NavItem key={index}>
								<NavLinks
									color={color}
									onClick={() => closeMobileMenu(el.to, el.id)}
									>
									{t(`navbar_data.${index}.text`)}
								</NavLinks>
								
							</NavItem>
						))}
						<LanguageSelect close={closeMobileMenu}/>
					</NavMenu>
				</NavbarContainer>
			</Nav>
		</IconContext.Provider>
	);
};

export default Navbar;