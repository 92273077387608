export const data = [
	{
		to: '/nosotros',
		text: '¿Quienes somos?',
		// id: 'about',
	},
	{
		to: '/',
		text: '¿Que ofrecemos?',
		id: 'about',
	},
	{
		to: '/productos',
		text: 'Productos',
	},
	{
		to: '/signup',
		text: 'Contacto',
	},
];