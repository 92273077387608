import React from 'react';

import { ImInfinite } from 'react-icons/im';
import { FaIndustry } from 'react-icons/fa';
import { FaSeedling } from 'react-icons/fa';
import { GiWaterDrop } from 'react-icons/gi';
import { MdReadMore } from 'react-icons/md';
const iconStyle = (Icon) => <Icon size="3rem" color="#ffffff" />;

export const featuresData = [
	{
		name: 'Multiplicación de semillas',
		description: iconStyle(MdReadMore),
		icon: iconStyle(ImInfinite),
		imgClass: 'one',
		link: './multiplicacióndesemillas'
	},
	{
		name: 'Semillas',
		description: iconStyle(MdReadMore),
		icon: iconStyle(FaSeedling),
		imgClass: 'two',
		link: './productos'
	},
	{
		name: 'Equipos de riego',
		description: iconStyle(MdReadMore),
		icon: iconStyle(GiWaterDrop),
		imgClass: 'three',
		link: './maquinaria'
	},
	{
		name: 'Planta clasificadora',
		description: iconStyle(MdReadMore),
		icon: iconStyle(FaIndustry),
		imgClass: 'three',
		link: './planta'
	}
];