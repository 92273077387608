import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from 'react-icons/fa';

const iconStyle = (Icon) => <Icon />;

export const footerSocialData = [
	
	{
		name: 'Instagram',
		icon: iconStyle(FaInstagram),
	}
];

export const footerData = [
	{
		title: 'Contactos',
		links: '/signup',
	},
	{
		title: 'Productos',
		links: '/productos',
	},
	// {
	// 	title: 'Press',
	// 	links: ['Logos', 'Events', 'Stories', 'Office'],
	// },
	// {
	// 	title: 'Legal',
	// 	links: ['GDPR', 'Privacy Policy', 'Terms of Service', 'Disclaimer'],
	// },
];