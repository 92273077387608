import styled from "styled-components";

export const WhatsappLink = styled.a`
    position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
    font-size:30px;
	box-shadow: 0 0 32px 8px #0367A6;
    z-index:100;
`

export const WhatsappIcon = styled.i`
    margin-top:16px;
`