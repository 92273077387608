import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
	FooterLinkItems,
	FooterLinkTitle,
	FooterLink,
	FooterLogo,
	SocialIcon,
	FooterRights,
	FooterSocialIcon,
	FooterWrapper,
	FooterAddress,
	FooterColumn,
	FooterGrid,
} from './FooterStyles';
import { footerData, footerSocialData } from '../../data/FooterData';
import { Row, Section } from '../../globalStyles';
import logo from '../../assets/logo2.0.png'

function Footer() {
	const { t } = useTranslation();
	return (
		<Section padding="4rem 0 2rem 0">
			<FooterWrapper>
				<FooterGrid justify="space-between">
					<FooterColumn id="footerLogo">
						<FooterLogo 
							to="/" 
							onClick={() => {
										window.scrollTo({
										top: 0,
										behavior: "smooth"
										});
									}}>
							<SocialIcon src={logo} />
							Radixagro
						</FooterLogo>
						<FooterAddress>
							Av. Thames 122, San Isidro, Buenos Aires
						</FooterAddress>

						<Row align="center" margin="auto  0 0 0" gap="1rem">
							{footerSocialData.map((social, index) => (
								<FooterSocialIcon
									key={index}
									href="https://www.instagram.com/radixagro/"
									target="_blank"
									aria-label={social.name}
								>
									{social.icon}
								</FooterSocialIcon>
							))}
						</Row>
					</FooterColumn>
					{footerData.map((footerItem, index) => (
						<FooterLinkItems key={index}>
							<Link 
								style={{textDecoration: 'none', color: 'white'}} 
								to={footerItem.links}
								onClick={() => {
									window.scrollTo({
									  top: 0,
									  behavior: "smooth"
									});
								  }}>
							<FooterLinkTitle>{t(`footer_data.${index}.title`)}</FooterLinkTitle>
							</Link>
							
						</FooterLinkItems>
					))}
				</FooterGrid>
				<FooterRights>Radixagro © 2023</FooterRights>
			</FooterWrapper>
		</Section>
	);
}

export default Footer;