import img1 from '../assets/planta.jpg'
import img2 from '../assets/background.jpeg'
import img3 from '../assets/vacas.jpg'

export const heroOne = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Radixagro se funda en 2017',
	},
	linkTo: "/nosotros",
	headline: "5 años de experiencia en el negocio",
	description: 'Adquirimos un proceso eficiente con maquinaria de alta tecnologia',
	buttonLabel: '+ Info',
	imgStart: 'start',
	img: img3,
	start: 'true',
};

export const heroTwo = {
	reverse: false,
	inverse: false,
	topLine: {
		text: 'Designed & Developed',
	},
	headline: 'Maquinaria de alta tecnologia',
	description: 'Contamos con una planta y maquinaria adecuada para entregar un producto de calidad',
	buttonLabel: '+ Info',
	linkTo: '/maquinaria',
	imgStart: 'start',
	img: img1,
	start: 'true',
};

export const heroThree = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Highly reputed brand',
	},
	headline: 'Porque nosotros? ',
	description:
		'Nos apasiona trabajar la tierra de tal manera que buscamos los mejores recursos para hacer nuestro trabajo',
	buttonLabel: '+ Info',

	linkTo: '/nosotros',
	imgStart: '',
	img: img2,
	start: 'true',
};