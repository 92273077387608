import React , { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import i18next from 'i18next'
import { BrowserRouter } from 'react-router-dom';
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import './index.css';
import App from './App';
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import Loader from './components/Loader/Loader';
// import 'flag-icon-css/css/flag-icon.min.css'

i18next
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    supportedLngs: ['es', 'en', 'chn'],
    fallbackLng: 'es',
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag', 'localStorage', 'subdomain'],
      caches: ['cookie'],
      whitelist: ['es', 'en', 'chn'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  })

  const loadingMarkup = (
    <div>
      <Loader />
    </div>
      

  )

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={loadingMarkup}>
  <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
