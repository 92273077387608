import React from 'react'
import { Container, Section, MainHeading } from '../globalStyles'
import { HeroText, HeroSection, HeroVideo } from '../components/Hero/HeroStyles'
import { useTranslation } from 'react-i18next'
import video from '../assets/plantaVideo.mp4'
import styled from 'styled-components'
import {
	ImgWrapper,
	Img,
} from '../components/Content/ContentStyle.js';


export default function EquipoRiego() {
    const { t } = useTranslation()

    const VideoContainer = styled.div`
      /* display: flex; */
      /* bottom: 0; */
      /* right: 0; */
      width: 80vw;
      height: 40vh;
      /* border: 1px solid #ccc; */
      margin: 0 auto;
      margin-bottom: 10rem;
      background-color: transparent;
      @media screen and (max-width: 768px) {
      
      width: 80vw;
      height: 40pvh;  
	}
    `;

    const VideoPlayer = styled.video`
      width: 100%;
      height: 100%;
    `;
  return (
    // <Section>
    //     <Container>
    //     <MainHeading style={{fontSize : "3rem"}}>Planta clasificadora</MainHeading>
    //     <HeroText style={{textAlign : "justify", textJustify : "inter-word"}}>
    //       En Radixagro Semillas, contamos con una línea capaz de clasificar a un ritmo considerable. Estos equipos de clasificación de semillas de primera línea nos permiten garantizar la calidad y la uniformidad de nuestras semillas antes de su venta, lo que nos ha permitido, no solo tener una altísima calidad de semilla clasificada propia, sino prestar servicio de clasificación de cualquier semilla a terceros. En conjunto con nuestra tecnología de riego y maquinaria agrícola, la línea de clasificación de Gessi es una herramienta clave en nuestro proceso de producción de semillas de alta calidad.
    //     </HeroText>
    //       </Container>
    //     </Section>
    <Section style={{height : "150vh"}}>
			<HeroVideo className='image'/>
			<Container>
				<MainHeading>{t("plantaTitle")}</MainHeading>
				<HeroText>
                {t("plantaText")}
        
    <br/>
    <br/>
    <br/>
    <br/>
    
    <VideoContainer>
      <VideoPlayer controls>
        <source src={video} type="video/mp4" />
      </VideoPlayer>
    </VideoContainer>
				</HeroText>
        
			</Container>
		</Section>
  )
}
