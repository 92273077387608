import React from 'react'
import {Section} from '../../globalStyles'
import './Loader.css'

export default function Loader() {
  return (

      <div class="item">
			<i class="loader --4"></i>
		</div>

    
  )
}
