import styled from 'styled-components';
import { Button } from '../../globalStyles';
import background from '../../assets/background.jpeg'
import backgroundHero from '../../assets/backgroundHero.jpg'



export const HeroSection = styled.section`
	height: 100vh;
	background-position: center;
	background-size: cover;
	padding-top: clamp(50px, 17.857vh, 157.14px);
	box-shadow: inset 0 0 0 1000px rgba (0, 0, 0, 0.2);

	@media (max-width: 980px) {
		height: 93vh;
	}
`;

export const HeroVideo = styled.div`
	object-fit: cover;
	width: 100%;
	height: 100%;
	background: linear-gradient(to right, rgba(2, 115, 115, 0.5) 0%, #027373 80%), url(${backgroundHero});
	background-size: cover;
		/* #90d5c6 */
	top: 0;
	position: absolute;
	z-index: -1;
	@media (max-width: 980px) {
		/* background-color: #3c787e; */
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
`;

export const HeroText = styled.p`
	margin-bottom: 35px;
	font-size: clamp(0.9rem, 1.5vw, 1.3rem);
	line-height: 24px;
	text-align: center;
	letter-spacing: 2px;
	color: #fff;
`;

export const ButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-flow: wrap;
	gap: 0.5rem;
`;

export const HeroButton = styled(Button)`
	color: black;
	&:before {
		background: #fff;
		height: 500%;
	}
	&:hover:before {
		height: 0%;
	}
	&:hover {
		color: white;
	}
`;