export const productData = [
	{
		title: "Festuca Flexible",
		type: "Forrajeras",
		price: "$4 p/Kg",
		features: [
			"Es un biotipo continental, lo que sugiere que es adecuada para su cultivo en climas templados o fríos",
			"Posee alta resistencia al pastoreo, lo que la hace ideal para su uso en pastizales destinados a la producción animal",
			"Presenta una floración temprana a intermedia, lo que significa que florece en los primeros meses del año",
			"La densidad de siembra recomendada es de 10 a 12 kilos por hectárea en siembra pura, o de 4 a 6 kilos por hectárea en mezclas",
			"Es una variedad de festuca que ha sido probada con éxito en varios ambientes de Argentina, lo que sugiere su adaptabilidad a diferentes condiciones climáticas y edafológicas"
			]
	},
	{
		title: "Festuca Rizomat",
		type: "Forrajeras",
		price: "$4 p/Kg",
		features: [
			"La Festuca Rizomat es una variedad adaptada a climas continentales, lo que la hace adecuada para su uso en diferentes regiones geográficas",
			"Esta especie destaca por su rusticidad, lo que significa que es capaz de soportar condiciones adversas como la sequía y el encharcamiento",
			"La Festuca Rizomat se caracteriza por tener un hábito de crecimiento rizomatoso, lo que permite una mejor recuperación después del pastoreo o el corte",
			"Se recomienda una densidad de siembra de 10 a 12 kilos por hectárea si se siembra pura, y de 4 a 6 kilos por hectárea en mezclas con otras especies",
			"La Festuca Rizomat es una variedad seleccionada por su tolerancia a manejos extensivos y encharcamientos frecuentes, lo que la hace adecuada para sistemas de pastoreo intensivo"
		]
	},
	{
		title: "Lotus Tenuis y Corniculatus",
		type: "Forrajeras",
		price: "$4 p/Kg",
		features: [
			"Ambas son plantas perennes", 
			"Tienen la capacidad de fijar nitrógeno en el suelo",
			"Son resistentes a la sequía y toleran bien los suelos pobres", 
			"Producen una gran cantidad de hojas y tallos que pueden ser utilizados para la producción de forraje de calidad", 
			"El Lotus Corniculatus es también utilizado en jardinería debido a sus flores amarillas"
		]
	},
	{
		title: "Sorgo Forrajero Coliqueo",
		type: "Forrajeras",
		price: "$4 p/Kg",
		features: [
			"El sorgo híbrido forrajero fotosensitivo está diseñado para no inducirse a la floración por un período muy extenso, gracias a la sensibilidad al fotoperiodo de la línea recuperadora Sudangrass, lo que garantiza un periodo vegetativo más prolongado y una mejor calidad forrajera.",
			"Este sorgo híbrido tiene un rápido rebrote, es folioso y tiene una gran aptitud para pastoreo directo, fardos, rollos, etc.",
			"Cañas finas: Las cañas del sorgo híbrido forrajero fotosensitivo son finas, lo que lo hace fácil de manejar y procesar.",
			"Bajo nivel de HCN: El sorgo híbrido forrajero fotosensitivo tiene un bajo nivel de HCN, lo que lo hace seguro para el consumo animal.",
			"Tiene hojas anchas y brillantes"
		]
	},
	{
		title: "Sorgo Granifero doble proposito",
		type: "Forrajeras",
		price: "$4 p/Kg",
		features: [
			"Doble propósito, lo que significa que es apto tanto para la producción de forraje como para la producción de grano",
			"Alto rendimiento en grano, lo que lo hace una opción atractiva para los productores que buscan maximizar la producción de este tipo de cultivo",
			"Ciclo medio a semitardío de 72-75 días a 50% de floración, lo que significa que se adapta bien a diferentes condiciones climáticas y puede ser utilizado en diferentes épocas del año",
			"Alto contenido de taninos condensados en el grano, lo que puede ser beneficioso para la salud del ganado y puede reducir el riesgo de enfermedades digestivas",
			"El Sorgo Graniferp Santa Lucia tiene una altura de planta de 1,80-1,90m y un alto volumen de rastrojo, lo que lo hace una opción atractiva para su uso como forraje y puede ser beneficioso para la conservación del suelo"
		]
	},
	{
		title: "RyeGrass Estanzuela 284",
		type: "Forrajeras y cesped",
		price: "$4 p/Kg",
		features: [
			"Alta producción de forraje, lo que lo hace ideal para su uso en pastoreo directo, henificación y silo",
			"Rápida regeneración, lo que permite una mayor cantidad de ciclos de pastoreo y una mayor producción de forraje a lo largo del tiempo", 
			"Es adaptable a diferentes climas y tipos de suelo, lo que la hace adecuada para su uso en diferentes regiones geográficas",
			"alto contenido de proteína, lo que lo hace ideal como alimento para el ganado y para maximizar la producción de leche o carne de alta calidad",
			"Alta digestibilidad, lo que significa que el ganado puede aprovechar mejor los nutrientes del forraje "
		]
	},
	{
		title: "Ryegrass Turftype Don Luis",
		type: "Cesped",
		price: "$4 p/Kg",
		features: [
			"La mezcla Don Luis combina dos tipos de Ryegrass, siendo el 95% del tipo Turf y el 5% restante del tipo Annual",
			"Uso profesional y ornamental: Esta mezcla es ideal tanto para uso profesional en áreas deportivas y jardines, como para uso ornamental en parques y espacios públicos",
			"El Ryegrass Turf Type le brinda a la mezcla una textura fina y suave al tacto, mientras que el color verde oscuro le da una apariencia atractiva y natural",
			"La mezcla Don Luis es resistente al tránsito y al pisoteo, lo que la hace ideal para zonas con alta actividad peatonal o para canchas deportivas",
			"Esta mezcla tiene una alta resistencia a enfermedades y una gran capacidad de persistir en el tiempo, lo que la convierte en una opción duradera y confiable para áreas verdes y deportivas"
		]
	},
	{
		title: "Mezcla Cordillerana",
		type: "Cesped",
		price: "$4 p/Kg",
		features: [
			"Adaptabilidad a diferentes climas: la mezcla Cordillerana se adapta bien a diferentes condiciones climáticas",
			"Alta producción de forraje: esta mezcla tiene una alta producción de forraje, ideal para sistemas de pastoreo y producción de forraje",
			"Calidad nutricional: Ryegrass Anual y Festuca tienen buena calidad nutricional para el ganadod",
			"Complementariedad de especies: la mezcla de especies mejora la calidad y cantidad del forraje",
			"Durabilidad: Ryegrass Anual es reemplazado por Festuca, lo que permite mantener la producción de forraje a largo plazo"
		]
	}
];