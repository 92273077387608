import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Section, MainHeading } from '../globalStyles'
import { HeroText } from '../components/Hero/HeroStyles'
import './MultiplicacionSemillas.css'
import styled from 'styled-components'
import video from '../assets/iFrameRadix.mp4'



export default function MultiplicacionSemillas() {
  const { t } = useTranslation()

  const VideoContainer = styled.div`
      /* position: fixed; */
      /* bottom: 0; */
      /* right: 0; */
      width: 720px;
      height: 520px;
      /* border: 1px solid #ccc; */
      margin: 0 auto;
      margin-bottom: 10rem;
      background-color: transparent;
      @media screen and (max-width: 768px) {
      width: 80vw;
      height: 20pvh;  
	}
    `;

    const VideoPlayer = styled.video`
      width: 100%;
      height: 100%;
    `;
  return (
        <Section>
          <Container>
          <MainHeading className='title'>{t("multiTitle")}</MainHeading>
          <HeroText style={{textAlign : "justify", textJustify : "inter-word"}}>
          {t("multiService")}
          <br />
          <br />
          {t("multiText")}
          <br/>
          <br/>
          {t("multiText1")}

          <br/>
          <br/>

          <VideoContainer>
      <VideoPlayer controls>
        <source src={video} type="video/mp4" mute />
      </VideoPlayer>
    </VideoContainer>
				</HeroText>
          </Container>
        </Section>
  )
}
