import React from 'react'
import GlobalStyle from './globalStyles';
import { BrowserRouter, Route, Routes,  useLocation } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import WhatsappButton from './components/Whatsapp/WhatsappButton';

//pages
import Home from './pages/HomePage';
import ProductsPage from './pages/ProductsPage';
import SignUpPage from './pages/SignUpPage';
import Blogdropdown from './pages/Blogdropdown';
import MultiplicacionSemillas from './pages/MultiplicacionSemillas'
import EquipoRiego from './pages/EquipoRiego'
import Quienessomos from './pages/Quienessomos'
import PlantaClasificadora from './pages/plantaClasificadora'

export default function App() {
  const location = useLocation();
  const navbarColor = location.pathname === '/productos' ? 'black' : 'white';
  return (
    <>
        <GlobalStyle />
        <Navbar color={navbarColor}/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/productos' element={<ProductsPage/>}/>
          <Route path='/signup' element={<SignUpPage/>}/>
          <Route path='/blog' element={<Blogdropdown/>}/>
          <Route path='/multiplicacióndesemillas' element={<MultiplicacionSemillas/>}/>
          <Route path='/maquinaria' element={<EquipoRiego/>}/>
          <Route path='/nosotros' element={<Quienessomos/>}/>
          <Route path='/planta' element={<PlantaClasificadora/>}/>
        </Routes>
        <WhatsappButton/>
        <Footer />
    </>
    
  )
}
